import React from 'react';
import styles from './AccountLayout.module.less';
import configs from "../../configs/config";


const AccountLayout = (props) => {
    return (

        <div className={styles.container}>
            <div className={styles.form}>
                <div className={styles.box}>
                    {props.children}
                </div>
                <div className={styles.copyright}>
                    {configs.appTitle} <br/> &copy; {configs.copyright}<br/> 
                    <a href={'https://beian.miit.gov.cn'} target="_blank">{configs.icp}</a>
                </div>                        

            </div>
        </div>
    )
}
export default AccountLayout;
