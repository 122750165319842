import React, { useState } from "react";
import { Table, Row, Col, Divider, Input, Tag } from "antd";
import { Link, useParams } from "react-router-dom";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import useTable from "../../hooks/useTable";
import style from "./order.module.less";
import DataSelect from "../../components/DataSelect.js";
import DictSelect from "../../components/DictSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import Util from "../../services/Util";
import moment from "moment";
import Segment from "../../components/Segment";
import { Typography } from 'antd';

const { Text } = Typography;
const OrderList = () => {
    
    const [page, setPage] = useState(0);
    const [merchantId, setMerchantId] = useState(null);
    const [pid, setPid] = useState({ id: -1 });
    const [dateRange, setDateRange] = useState( Util.dateRange(7 * 365, "days"));
    let num = useParams().orderNumber;
    const [queryWord, setQueryWord] = useState(num ? num : "");
    
    let overdue = useParams().overdue;
    const [overdueStatu, setOverdueStatu] = useState(overdue ? overdue : "ALL");

    let statusId = useParams().status;
    const [status, setStatus] = useState(statusId ? {id : statusId} : "");
    


    const overdueStatus = [
        { id: "ALL", title: "全部" },
        { id: "OVERDUE", title: "已逾期" },
        { id: "NOTOVERDUE", title: "未逾期" }
    ];

    const columns = [
        {
            title: "订单号",
            dataIndex: "orderNumber",
            width: 200,
            render: (text, record) => (
                <div>
                    {record.status.id === "ORDER_STATUS_PAIED" &&
                    new Date().getTime() >
                        moment(record.closeTime).toDate().getTime() ? (
                        <>
                           <Text strong>{record.orderNumber}</Text>
                            <Tag color="red">逾期</Tag>
                        </>
                    ) : (
                        <Text strong>{record.orderNumber}</Text>
                    )}
                </div>
            ),
        },
        {
            title: "类型",
            dataIndex: ["orderType", "title"],
            render: (text, record) => (
                <Text code>{record.orderType.title}</Text>
            ),
        },
        {
            title: "设备",
            width: 180,
            dataIndex: ["device", "uniqueId"],
            render: (text, record) => (
                <Tag color="warning">{record.device.uniqueId}</Tag>
            ),
        },
        {
            title: "创建时间",
            width: 150,
            dataIndex: ["createTime"],
        },
        {
            title: "开/关时间",
            dataIndex: "deviceOpenTime",
            width: 250,
            render: (text, record) => (
                <div>
                    {record.deviceOpenTime ? record.deviceOpenTime : "-"} 
                    <br />
                    {record.deviceCloseTime ? record.deviceCloseTime : "-"}
                </div>
            ),
        },
        {
            title: "结束方式",
            dataIndex: ["closeType"],
            render: (text, record) => (
                <div>
                    {
                        record.closeType === 1
                         ? "用户手动关闭"
                         : record.closeType === 2
                         ? "管理员手动关闭"
                         : record.closeType === 3
                         ? "自动关闭"
                         : record.closeType === 4
                         ? "批量自动关闭"
                         : "-"
                    }
                </div>
            ),
        },
        {
            title: "状态",
            dataIndex: ["status", "title"],
            render: (id, record) => {
                let className = "default";
                if (record.status.id === 'ORDER_STATUS_SUBMIT') {
                    className = "warning";
                } else if (record.status.id === 'ORDER_STATUS_PAIED') {
                    className = "processing";
                } else if (record.status.id === 'ORDER_STATUS_FINISHED') {
                    className = "success";
                } 

                return <Tag color={className}>
                    {record.status.title}
                </Tag>
            },
        },
        {
            title: "商户",
            width: 160,
            dataIndex: ["position", "merchant", "title"],
        },
        {
            title: "场所",
            width: 160,
            dataIndex: ["position", "title"],
        },
        {
            title: "用户",
            width: 150,
            dataIndex: ["user", "userName"],
        },
        

        

        {
            title: "预付费",
            dataIndex: "preFee",
            width: 100,
            render: (text, record) => (
                <Text code>{(record.preFee / 100).toFixed(2)}</Text>
            ),
        },
        {
            title: "扣费",
            width: 100,
            dataIndex: ["fee"],
            render: (text, record) => (
                <Text code>{(record.fee / 100).toFixed(2)}</Text>
            ),
        },
        
        {
            title: "预计归还时间",
            width: 150,
            dataIndex: ["closeTime"],
        },
        // {
        //     title: "开锁方式",
        //     dataIndex: "openType",
        //     render: (text, record) => (
        //         <div>
        //             {record.openType === 2
        //                 ? "NB开锁"
        //                 : record.openType === 3
        //                 ? "蓝牙开锁"
        //                 : record.openType === 4
        //                 ? "机械开锁"
        //                 : "未知"}
        //         </div>
        //     ),
        // },
        // {
        //     title: "归还状态",
        //     dataIndex: "returnType",
        //     render: (text, record) => (
        //         <div>
        //             {record.returnType === 0
        //                 ? "异常"
        //                 : record.returnType === 1
        //                 ? "正常"
        //                 : "未知"}
        //         </div>
        //     ),
        // },
                
        {
            title: "操作",
            dataIndex: "id",
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <div>
                    <Link to={"/order/orderDetail/" + record.id}>详情</Link>
                    <Divider type="vertical" />
                    <Link to={"/fee/payList/" + record.orderNumber}>支付</Link>
                </div>
            ),
        },
    ];

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    const onStatusChange = (status) => {
        setStatus(status);
    };

    const changePosition = (position) => {
        setPid(position);
    };
    const onMerchantChange = (merchant) => {
        setMerchantId(merchant);
    };

    const { tableProps, reloadTable } = useTable({
        url: "/orders/query",
        pageSize: 10,
        queryParams: {
            page,
            queryWord,
            overdueStatu ,
            pid: pid ? pid.id : "",
            merchantId: merchantId ? merchantId.id : "",
            status: status ? status.id : "",
            startDate: dateRange[0],
            endDate: dateRange[1],
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DataSelect
                            allowClear
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onStatusChange}
                            categoryKey="ORDER_STATUS"
                            placeholder="状态"
                        />
                        <DataSelect
                            allowClear
                            onChange={changePosition}
                            dataUrl="/positions/validPositions"
                            placeholder="场所"
                            className={style.dict}
                        />
                        <Segment
                            onChange={(g) => {
                                setOverdueStatu(g);
                            }}
                            selectIndex={overdue ? 1 : 0}
                            list={overdueStatus}
                        />
                        <Input.Search
                            placeholder="订单编号"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} scroll={{ x: 2200, y: 800 }}/>
            </ModuleContent>
        </BasicLayout>
    );
};

export default OrderList;
