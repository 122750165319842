import React, { useState, useEffect } from "react";
import { Popconfirm, Divider, Input, Button, message, Descriptions } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleHeader,
} from "../../layouts/basic/BasicLayout";
import { useParams, useHistory } from "react-router-dom";
import useForm from "../../hooks/useForm";
import style from "./order.module.less";
import SpinBox from "../../components/SpinBox";
import Main from "../share/Main";
import API from "../../services/Api";

const OrderDetail = () => {
    let history = useHistory();
    const id = useParams().id;
    const [order, setOrder] = useState("");
    const [loading, setLoading] = useState(false);
    const closeOrder = () => {
        API.post(`/orders/closeOrder`, { oId: order.id }).then((res) => {
            message.success("结束订单成功！");
            history.goBack();
        });
    };

    const forceCloseOrder = () => {
        API.post(`/orders/forceCloseOrder`, { oId: order.id }).then((res) => {
            message.success("结束订单成功！");
            history.goBack();
        });
    };

    const fetch = () => {
        setLoading(true);
        API.post("/orders/get", {
            id: id,
        }).then((res) => {
            const data = res.data;
            setOrder({
                id: data.id,
                orderNumber: data.orderNumber,
                statusId: data.status.id,
                statusTitle: data.status.title,
                senderUserName: data.user.userName,
                lastOpenSuccess: data.lastOpenSuccess,
                positionTitle: data.position.title,
                merchantTitle: data.position.merchant.title,
                slotFromTitle: data.slotFrom ? (data.slotFrom.stub.uniqueId + "#" + data.slotFrom.idx) : "",
                slotToTitle: data.slotTo ? (data.slotTo.stub.uniqueId + "#" + data.slotTo.idx): "",
                deviceTitle: data.device.title,
                orderTypeTitle: data.orderType ? data.orderType.title : "",
                fee: (data.fee / 100).toFixed(2),
                closeType:
                    data.closeType === 1
                        ? "小程序手动关闭"
                        : data.closeType === 2
                        ? "管理员关闭"
                        : data.closeType === 3
                        ? "自动关闭"
                        : data.closeType === 4
                        ? "批量自动关闭"
                        : "-",
                preFee: (data.preFee / 100).toFixed(2),
                freeTime: data.freeTime,
                chargeUnit: data.chargeUnit,
                chargeUnitFee: (data.chargeUnitFee / 100).toFixed(2),
                duration: data.duration,
                chargeMaxFeePerDuration: (
                    data.chargeMaxFeePerDuration / 100
                ).toFixed(2),
                durationFee: (data.durationFee / 100).toFixed(2),
                overdueChargeUnit: data.overdueChargeUnit,
                overdueChargeFee: (data.overdueChargeFee / 100).toFixed(2),
                overdueMaxChargeFee: (data.overdueMaxChargeFee / 100).toFixed(
                    2
                ),
                platformRate: data.platformRate,
                openType:
                    data.openType === 2
                        ? "NB开锁"
                        : data.openType === 3
                        ? "蓝牙开锁"
                        : data.openType === 4
                        ? "机械开锁"
                        : "未知",
                returnType:
                    data.returnType === 0
                        ? "异常"
                        : data.returnType === 1
                        ? "正常"
                        : "未知",
                startTime: data.startTime,
                closeTime: data.closeTime ? data.closeTime : "-",
                deviceOpenTime: data.deviceOpenTime ? data.deviceOpenTime : "-",
                deviceCloseTime: data.deviceCloseTime ? data.deviceCloseTime : "-",
                createTime: data.createTime,
                finishTime: data.finishTime ? data.finishTime : "-",
                payTime: data.payTime ? data.payTime : "-",
                lastModifyTime: data.lastModifyTime,
            });
            setLoading(false);
        });
    };

    useEffect(() => {
        fetch();
    }, []);

    return (
        <BasicLayout>
            <ModuleContent>
                <ModuleHeader title="订单详情" />
                <SpinBox loading={loading}>
                    <Descriptions
                        title="基本信息"
                        style={{ marginTop: 15, padding: 10 }}
                    >
                        <Descriptions.Item label="订单号">
                            {order.orderNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label="扣费">
                            {order.fee}
                        </Descriptions.Item>
                        <Descriptions.Item label="状态">
                            {order.statusTitle}
                        </Descriptions.Item>
                        <Descriptions.Item label="用户">
                            {order.senderUserName}
                        </Descriptions.Item>
                        <Descriptions.Item label="场所">
                            {order.positionTitle}
                        </Descriptions.Item>
                        <Descriptions.Item label="商户">
                            {order.merchantTitle}
                        </Descriptions.Item>
                        <Descriptions.Item label="结束方式">
                            {order.closeType}
                        </Descriptions.Item>
                        <Descriptions.Item label="已开锁">
                            {order.lastOpenSuccess === true ? "是" : "否"}
                        </Descriptions.Item>
                        <Descriptions.Item label="设备">
                            {order.deviceTitle}
                        </Descriptions.Item>
                        <Descriptions.Item label="借出桩">
                            {order.slotFromTitle}
                        </Descriptions.Item>
                        <Descriptions.Item label="归还桩">
                            {order.slotToTitle}
                        </Descriptions.Item>
                        
                        <Descriptions.Item label="规则类型">
                            {order.orderTypeTitle}
                        </Descriptions.Item>
                        
                        <Descriptions.Item label="开锁方式">
                            {order.openType}
                        </Descriptions.Item>
                        <Descriptions.Item label="归还状态">
                            {order.returnType}
                        </Descriptions.Item>
                        <Descriptions.Item label="订单创建时间">
                            {order.createTime}
                        </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions
                        title="计费规则"
                        style={{ marginTop: 15, padding: 10 }}
                    >
                        <Descriptions.Item label="预付费">
                            {order.preFee}
                        </Descriptions.Item>
                        
                        <Descriptions.Item label="免费时间">
                            {order.freeTime}分钟
                        </Descriptions.Item>
                        <Descriptions.Item label="计费周期">
                            {order.chargeUnit}分钟
                        </Descriptions.Item>
                        <Descriptions.Item label="每计费周期费用">
                            {order.chargeUnitFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="时长">
                            {order.duration}分钟
                        </Descriptions.Item>
                        <Descriptions.Item label="周期封顶金额">
                            {order.chargeMaxFeePerDuration}
                        </Descriptions.Item>
                        <Descriptions.Item label="一口价">
                            {order.durationFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="逾期扣费周期">
                            {order.overdueChargeUnit}分钟
                        </Descriptions.Item>
                        <Descriptions.Item label="每逾期周期扣费">
                            {order.overdueChargeFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="逾期最多扣费">
                            {order.overdueMaxChargeFee}
                        </Descriptions.Item>
                        <Descriptions.Item label="平台分成比例">
                            {order.platformRate}
                        </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    <Descriptions
                        title="时间信息"
                        style={{ marginTop: 15, padding: 10 }}
                    >
                        <Descriptions.Item label="支付时间">
                            {order.payTime}
                        </Descriptions.Item>
                        <Descriptions.Item label="最后开锁时间">
                            {order.deviceOpenTime}
                        </Descriptions.Item>
                        <Descriptions.Item label="最后关锁时间">
                            {order.deviceCloseTime}
                        </Descriptions.Item>
                        <Descriptions.Item label="开始时间预设">
                            {order.startTime}
                        </Descriptions.Item>
                        <Descriptions.Item label="结束时间预设">
                            {order.closeTime}
                        </Descriptions.Item>
                        <Descriptions.Item label="订单结束时间">
                            {order.finishTime}
                        </Descriptions.Item>
                        
                        <Descriptions.Item label="最后更新时间">
                            {order.lastModifyTime}
                        </Descriptions.Item>
                    </Descriptions>
                    <Divider />
                    
                    {order.statusId === "ORDER_STATUS_PAIED" &&
                    Main.isMerchant() ? (
                        <Popconfirm
                            title="确定结束订单吗?"
                            loading={loading}
                            onConfirm={closeOrder}
                        >
                            <Button danger>结束订单</Button>
                        </Popconfirm>
                    ) : (
                        ""
                    )}
                    {" "}
                    {order.statusId === "ORDER_STATUS_PAIED" &&
                    Main.isMerchant() ? (
                        <Popconfirm
                            title="确定强制结束订单吗? (警告：强制结束订单后设备状态会置为维修状态)"
                            loading={loading}
                            onConfirm={forceCloseOrder}
                        >
                            <Button danger>强制结束订单</Button>
                        </Popconfirm>
                    ) : (
                        ""
                    )}
                </SpinBox>
            </ModuleContent>
        </BasicLayout>
    );
};

export default OrderDetail;
