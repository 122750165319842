import React, { useState, useEffect } from "react";
import { Row, Col, Space, Table, Button, DatePicker } from "antd";

import API from "../../services/Api";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import style from "./report.module.less";
import moment from "moment";
import ExportJsonExcel from "js-export-excel";
import { Typography, Tag } from 'antd';
import DataSelect from "../../components/DataSelect.js";
import { DownloadOutlined } from "@ant-design/icons";
const { Text } = Typography;


const WithdrawReport = () => {

    const [date, setDate] = useState(moment());
    const [merchantId, setMerchantId] = useState("");

    const params = { date: date ? moment(date).format("YYYY-MM-DD 23:59:59") : moment().format("YYYY-MM-DD 23:59:59"), merchantId };

    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(true);

    const dateFormat = 'YYYY/MM/DD'

    let queryDeps = params
        ? Object.keys(params).map((key) => params[key])
        : [];




    const tranformData = (data) => {
        const newData = [];
        data.forEach(item => {
            let result = newData.filter((element) => {
                return item.mid == element.id
            })

            let subItem = { id: item.mid };
            if (result.length > 0) {
                subItem = result[0]
            } else {
                newData.push(subItem);
            }

            subItem["title"] = item.title;

            if (item["status"] == "WITHDRAW_STATUS_SUCCESS") {
                subItem["success"] = item["fee"];
            }
            if (item["status"] == "WITHDRAW_STATUS_FAILURE") {
                subItem["failure"] = item["fee"];
            }
            if (item["status"] == "WITHDRAW_STATUS_SUBMIT") {
                subItem["submit"] = item["fee"];
            }
        });
        return newData
    }
    const columns = [
        {
            title: "商户",
            dataIndex: "title",
            render: (text, record) => <Text strong>{record.title}</Text>,
        },
        {
            title: "未提",
            dataIndex: "submit",
            render: (text, record) => <Tag color="#2db7f5">{record.submit ? (record.submit / 100).toFixed(2) : 0}</Tag>,
        },
        {
            title: "失败",
            dataIndex: "failure",
            render: (text, record) => <Tag color="#f50">{record.failure ? (record.failure / 100).toFixed(2) : 0}</Tag>,
        },
        {
            title: "已提",
            dataIndex: "success",
            render: (text, record) => <Tag color="#87d068">{record.success ? (record.success / 100).toFixed(2) : 0}</Tag>,
        },


    ];

    const fetch = () => {
        API.post("/withDraws/reportQuery", params).then((res) => {
            if (res && res.data) {
                let newData = tranformData(res.data);
                setReportData(newData);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        fetch();
    }, queryDeps);


    const dateRangeChange = (oDate, dateString) => {
        setDate(oDate);
    };

    const onMerchantChange = (merchant) => {
        setMerchantId(merchant ? merchant.id : "");
    };

    const downloadExcel = () => {
        var option = {};
        let dataTable = [];
        if (reportData) {
            for (let i in reportData) {
                let obj = {                 
                    '商户': reportData[i].title,
                    '未提': reportData[i].submit ? (reportData[i].submit / 100).toFixed(2) : 0,
                    '失败': reportData[i].failure ? (reportData[i].failure / 100).toFixed(2) : 0,
                    '已提': reportData[i].success ? (reportData[i].success / 100).toFixed(2) : 0,
                }
                dataTable.push(obj);
            }
        }
        option.fileName = '提现报表-' + moment(date).format("YYYY-MM-DD"); // 文件名
        option.datas = [
            {
                sheetData: dataTable, // 数据源
                sheetName: '提现报表', // excel中表名
                sheetFilter: ['商户', '未提', '失败', '已提'],
                sheetHeader: ['商户', '未提', '失败', '已提'], // 表格头部
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    };

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="1">
                        <Button type="dashed" style={{ marginRight: '20px' }} icon={<DownloadOutlined />} onClick={downloadExcel}>导出</Button>

                    </Col>
                    <Col flex="auto" className={style.query_area}>
                        <Space>

                            <DatePicker onChange={dateRangeChange} defaultValue={date} format={dateFormat} />
                            <DataSelect
                                allowClear                                                                                                  
                                onChange={onMerchantChange}
                                dataUrl="/merchants/listAll"
                                placeholder="商户"                                
                                className={style.dict}                                                                  
                                style={{width:"100px"}}                                                          
                            />
                        </Space>
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table
                    dataSource={reportData}
                    size="default"
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                    loading={loading}
                />
            </ModuleContent>
        </BasicLayout>
    );
};



export default WithdrawReport;
