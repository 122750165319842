import React, { useState } from "react";
import { Table, Row, Col, Input, Button, Divider, Popconfirm, Modal, Form, Tag, Space } from "antd";
import BasicLayout, {
    ModuleContent,
    ModuleTool,
} from "../../layouts/basic/BasicLayout";
import { DownloadOutlined } from '@ant-design/icons';
import { Link, useParams } from "react-router-dom";
import DictSelect from "../../components/DictSelect";
import DateRangeSelect from "../../components/DateRangeSelect";
import useTable from "../../hooks/useTable";
import DataSelect from "../../components/DataSelect.js";
import style from "./device.module.less";
import Util from "../../services/Util";
import API from "../../services/Api";
import Main from "../share/Main";
import { Typography} from 'antd';
import moment from "moment";
const { Text} = Typography;
const SlotList = () => {
    const [queryWord, setQueryWord] = useState("");
    const [page, setPage] = useState(0);
    const [merchantId, setMerchantId] = useState(null);
    const [positionId, setPositionId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [dateRange, setDateRange] = useState(Util.dateRange(7 * 365, "days"));
    let statusId = useParams().status;
    const [status, setStatus] = useState(statusId ? { id: statusId } : "");

    const [showModal, setShowModal] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const [modalForm] = Form.useForm();
    const path = "/device/slotEdit/";


    const renderContent = (id, record, idx, children) => {
        let obj = {
            children: children,
            props: {},
        }

        let rowSpans = tableProps.dataSource.filter((item) => (
            item.stub.id == record.stub.id
        )).length;

        obj.props.rowSpan = rowSpans;

        if (idx > 0 && (tableProps.dataSource[idx - 1].stub.id == tableProps.dataSource[idx].stub.id)) {
            obj.props.rowSpan = 0;
        }

        return obj;
    }


    const columns = [
        {
            title: "名称/编码",
            dataIndex: ["stub", "title"],
            width: 180,
            render: (id, record, idx) => {
                let content = 
                <>
                    <Text strong>{record.stub.title}</Text>                                   
                    <Text strong>{record.stub.uniqueId}</Text>
                </>                            
                return renderContent(id, record, idx, content);
            }

        },

       
        {
            title: "商户",
            dataIndex: ["stub", "merchant", "title"],
            render: (id, record, idx) => {
                let content = <div>
                    <span>{record.stub.merchant.title}</span>
                </div>;
                return renderContent(id, record, idx, content);
            }
        },
        {
            title: "场所",
            dataIndex: ["stub", "position", "title"],
            render: (id, record, idx) => {
                if(record.stub.position){
                    let content = <div>
                        <span>{record.stub.position.title}</span>
                    </div>;
                    return renderContent(id, record, idx, content);
                }else{
                    return <>-</>
                }
            }
        },
        {
            title: "类型",
            dataIndex: ["stub", "deviceCategory", "title"],
            render: (id, record, idx) => {
                let content = <div>
                    <Text code>{record.stub.deviceCategory.title}</Text>
                </div>;
                return renderContent(id, record, idx, content);
            }
        },
        {
            title: "槽号",
            dataIndex: ["idx"],
            render: (id, record) => (
                <div>
                    <Text code>#{record.idx}</Text>
                </div>
            ),
        },
        {
            title: "状态",
            dataIndex: ["status", "title"],
            render: (id, record) => {
                let className = "default";
                if (record.status.id === 'DEVICE_STATUS_FREE') {
                    className = "success";
                } else if (record.status.id === 'DEVICE_STATUS_MAINTAIN') {
                    className = "error";
                } else if (record.status.id === 'DEVICE_STATUS_UNDISPATCH') {
                    className = "warning";
                } else if (record.status.id === 'DEVICE_STATUS_USE') {
                    className = "processing";
                }

                return <Tag color={className}>
                    {record.status.title}
                </Tag>
            },
        },
        {
            title: "电量/信号",
            dataIndex: ["stub", "battery"],
            render: (id, record, idx) => {
                let content = <div>
                    <Text code>{record.stub.battery + "/" + record.stub.rssi}</Text>
                </div>;
                return renderContent(id, record, idx, content);
            }
        },

        // {
        //     title: "索引",
        //     dataIndex: ["idx"],
        // },
        // {
        //     title: "开锁方式",
        //     dataIndex: ["openType"],
        //     render: (id, record) => (
        //         <div>
        //             <span>
        //                 {record.openType === 2
        //                     ? "NB开锁"
        //                     : record.openType === 3
        //                     ? "蓝牙开锁"
        //                     : record.openType === 4
        //                     ? "机械开锁"
        //                     : "未知"}
        //             </span>
        //         </div>
        //     ),
        // },
        // {
        //     title: "归还状态",
        //     dataIndex: ["returnType"],
        //     render: (id, record) => (
        //         <div>
        //             <span>
        //                 {record.returnType === 0
        //                     ? "异常"
        //                     : record.returnType === 1
        //                     ? "正常"
        //                     : "未知"}
        //             </span>
        //         </div>
        //     ),
        // },
        {
            title: "创建时间",
            dataIndex: ["stub", "createTime"],
            render: (id, record, idx) => {
                let content = <div>
                    <span>{moment(record.createTime).format("YYYY-MM-DD HH:mm:ss")}</span>
                </div>;
                return renderContent(id, record, idx, content);
            }
        },
        {
            title: "上报时间",
            dataIndex: ["stub", "lastUpdateTime"],
            render: (id, record, idx) => {
                let content = <div>
                    <span>{moment(record.stub.lastUpdateTime).format("YYYY-MM-DD HH:mm:ss")}</span>
                </div>;
                return renderContent(id, record, idx, content);
            }
        },
        {
            title: "操作",
            dataIndex: ["id"],
            fixed: 'right',
            width: 100,
            render: (text, record) => (
                <div>
                    <a onClick={() => onModalOpen(record)}>编辑</a>
                    {Main.isMerchant() ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定要删除吗?"
                                onConfirm={() => deleteRow(record.id)}
                            >
                                <a>删除</a>
                            </Popconfirm>
                        </>
                    ) : (
                        ""
                    )}

                    {(Main.isMerchant() &&
                        record.status.id === "DEVICE_STATUS_USE") ||
                        record.status.id === "DEVICE_STATUS_FREE" ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定要维修吗?"
                                onConfirm={() => stubMaintain(record.id)}
                            >
                                <a>维修</a>
                            </Popconfirm>
                        </>
                    ) : record.status.id === "DEVICE_STATUS_MAINTAIN" ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="确定要维修完成吗?"
                                onConfirm={() => maintainFinished(record.id)}
                            >
                                <a>维修完成</a>
                            </Popconfirm>
                        </>
                    ) : (
                        ""
                    )}

                    {(Main.isMerchant() &&
                        record.stub.deviceType.id === "DEVICE_TYPE_BLE_NB") ? (
                        <>
                            <Divider type="vertical" />
                            <Popconfirm
                                title="请先联系现场人员按下开锁按钮!"
                                onConfirm={() => unlock(record.id)}
                            >
                                <a>开锁</a>
                            </Popconfirm>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            ),
        },
    ];

    const { tableProps, reloadTable } = useTable({
        url: "slots/query",
        queryParams: {
            page,
            queryWord: queryWord,
            startDate: dateRange[0],
            merchantId: merchantId ? merchantId.id : null,
            positionId: positionId ? positionId.id : null,
            categoryId: categoryId ? categoryId.id : null,
            statusId: status ? status.id : "",
            endDate: dateRange[1],
        },
        onPageChange: (page) => {
            setPage(page);
        },
    });

    const onStatusChange = (status) => {
        setStatus(status);
    };

    const onMerchantChange = (merchant) => {
        setMerchantId(merchant);
    };

    const onCategoryChange = (category) => {
        setCategoryId(category);
    };


    const onPositionChange = (position) => {
        setPositionId(position);
    };

    const dateRangeChange = ([startDate, endDate]) => {
        setDateRange([startDate, endDate]);
    };

    const deleteRow = (id) => {
        API.post(`/slots/delete`, { id }).then((res) => {
            reloadTable();
        });
    };

    const unlock = (id) => {
        API.post(`/slots/unlock`, { id }).then((res) => {
        });
    }

    const stubMaintain = (id) => {
        API.post(`/slots/stubMaintain`, { id }).then((res) => {
            reloadTable();
        });
    };

    const maintainFinished = (id) => {
        API.post(`/slots/maintainFinished`, { id }).then((res) => {
            reloadTable();
        });
    };

    const downloadTemplate = e => {
        document.location.href =
            process.env.REACT_APP_API_HOST +
            `/slots/downloadTemplate`;
    }


    const onModalOpen = (record) => {
        setSelectedItem(record);
        modalForm.setFieldsValue({
            uniqueId: record.stub.uniqueId,
            title: record.stub.title,
        });
        setShowModal(true)
    };

    const onModalCancel = () => {
        setShowModal(false);
    }

    const onModalSubmit = () => {
        API.post(`/stubs/updateTitle`, { id: selectedItem.stub.id, title: modalForm.getFieldValue("title") }).then((res) => {
            reloadTable();
            setSelectedItem(null);
            onModalCancel();
        });
    }

    return (
        <BasicLayout>
            <ModuleTool>
                <Row>
                    <Col flex="300px">
                        {Main.isMerchant() ? (
                            <>
                                <Button>
                                    <Link to="/device/stubUpload">
                                        批量导入
                                    </Link>
                                </Button>{" "}
                                <Button type="dashed" icon={<DownloadOutlined />}>
                                    <a onClick={downloadTemplate}>
                                        {" "}模板下载
                                    </a>
                                </Button>
                            </>
                        ) : (
                            ""
                        )}
                    </Col>

                    <Col flex="auto" className={style.query_area}>
                        <DateRangeSelect
                            value={dateRange}
                            onChange={dateRangeChange}
                        />
                        <DataSelect
                            allowClear
                            onChange={onMerchantChange}
                            dataUrl="/merchants/listAll"
                            placeholder="商户"
                            className={style.dict}
                        />

                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onCategoryChange}
                            categoryKey="DEVICE_CATEGORY"
                            placeholder="桩类型"
                        />

                        <DataSelect
                            allowClear
                            onChange={onPositionChange}
                            dataUrl="/positions/listAll"
                            placeholder="场所"
                            className={style.dict}
                        />
                        <DictSelect
                            allowClear
                            className={style.dict}
                            onChange={onStatusChange}
                            categoryKey="DEVICE_STATUS"
                            placeholder="状态"
                        />
                        <Input.Search
                            placeholder="搜索"
                            onSearch={(g) => {
                                setQueryWord(g);
                            }}
                            className={style.query}
                        />
                    </Col>
                </Row>
            </ModuleTool>
            <ModuleContent>
                <Table {...tableProps} columns={columns} scroll={{ x: 1500, y: 800 }} />
            </ModuleContent>
            <Modal
                title="编辑桩名称"
                visible={showModal}
                footer={null}
                getContainer={false}
                onCancel={onModalCancel}
            >
                <Form
                    onFinish={onModalSubmit}
                    form={modalForm}
                    wrapperCol={{ span: 14 }}
                    labelCol={{ span: 7 }}
                >
                    <Form.Item
                        label="桩编码"
                        name="uniqueId"
                    >
                        <Input readOnly={true} />
                    </Form.Item>
                    <Form.Item
                        label="桩名称"
                        name="title"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 14, offset: 7 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </BasicLayout>
    );
};

export default SlotList;
